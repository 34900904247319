body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
    font-family: 'Inter';
    src: url('./fonts/Inter-VariableFont_slnt,wght.ttf') format('truetype-variations');
    font-weight: 100 900;
    font-style: oblique 0deg 10deg;
}


@font-face {
    font-family: 'NunitoItalic';
    src: url('./fonts/Nunito-Italic-VariableFont_wght.ttf') format('truetype');
    font-weight: normal;
}

@font-face {
    font-family: 'Nunito';
    src: url('./fonts/Nunito-VariableFont_wght.ttf') format('truetype');
    font-weight: normal;
}

@font-face {
    font-family: 'RobotoSlab';
    src: url('./fonts/RobotoSlab-VariableFont_wght.ttf') format('truetype');
    font-weight: normal;
}


@font-face {
    font-family: 'Valera';
    src: url('./fonts/VarelaRound-Regular.ttf') format('truetype');
    font-weight: normal;
}

@font-face {
    font-family: 'IBMPlexMono';
    src: url('./fonts/IBMPlexMono-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'IBMPlexMono';
    src: url('./fonts/IBMPlexMono-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'IBMPlexMono';
    src: url('./fonts/IBMPlexMono-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    /* Extra Light */
    font-style: normal;
}

@font-face {
    font-family: 'IBMPlexMono';
    src: url('./fonts/IBMPlexMono-ExtraLightItalic.ttf') format('truetype');
    font-weight: 200;
    /* Extra Light */
    font-style: italic;
}

@font-face {
    font-family: 'IBMPlexMono';
    src: url('./fonts/IBMPlexMono-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'IBMPlexMono';
    src: url('./fonts/IBMPlexMono-Light.ttf') format('truetype');
    font-weight: 300;
    /* Light */
    font-style: normal;
}

@font-face {
    font-family: 'IBMPlexMono';
    src: url('./fonts/IBMPlexMono-LightItalic.ttf') format('truetype');
    font-weight: 300;
    /* Light */
    font-style: italic;
}

@font-face {
    font-family: 'IBMPlexMono';
    src: url('./fonts/IBMPlexMono-Medium.ttf') format('truetype');
    font-weight: 500;
    /* Medium */
    font-style: normal;
}

@font-face {
    font-family: 'IBMPlexMono';
    src: url('./fonts/IBMPlexMono-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    /* Medium */
    font-style: italic;
}

@font-face {
    font-family: 'IBMPlexMono';
    src: url('./fonts/IBMPlexMono-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'IBMPlexMono';
    src: url('./fonts/IBMPlexMono-SemiBold.ttf') format('truetype');
    font-weight: 600;
    /* SemiBold */
    font-style: normal;
}

@font-face {
    font-family: 'IBMPlexMono';
    src: url('./fonts/IBMPlexMono-SemiBoldItalic.ttf') format('truetype');
    font-weight: 600;
    /* SemiBold */
    font-style: italic;
}

@font-face {
    font-family: 'IBMPlexMono';
    src: url('./fonts/IBMPlexMono-Thin.ttf') format('truetype');
    font-weight: 100;
    /* Thin */
    font-style: normal;
}

@font-face {
    font-family: 'IBMPlexMono';
    src: url('./fonts/IBMPlexMono-ThinItalic.ttf') format('truetype');
    font-weight: 100;
    /* Thin */
    font-style: italic;
}