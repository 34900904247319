body{
    
    background-color:#e9e9e9;
}

pre{
    font-size:1.5rem;
}

.hover::after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #0d0d0d;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
}

.hover:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
}



a[href="/about"] {
    color: #0087ff;
}

a[href="/projects"] {
    color: #ffba01;
}

a[href="/contact"] {
    color:#7ad926;
}